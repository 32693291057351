import * as api from 'api/topics/topics.api';

import * as application from 'store/application';

import { createAsyncThunk } from '../utils';

import { ICreateParams } from './types';

export const fetch = createAsyncThunk(
  'topics:fetch',
  async function (groupId: string, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(api.list(groupId));

    return data;
  },
);

export const create = createAsyncThunk(
  'topics:create',
  async function (params: ICreateParams, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      api.create(params.groupId, params.label),
    );

    thunkAPI.dispatch(
      application.actions.showToast({
        type: 'success',
        i18nParams: { i18nKey: 'groups-web.toast.topic.create' },
      }),
    );

    return data;
  },
  {
    formatError: (params) => ({
      i18nKey: 'groups-web.toast.error.topics.create',
      values: {
        name: params.label,
      },
    }),
  },
);
