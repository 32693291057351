import type { RawParams } from '@wix/tpa-router';

import type { StateDeclarationFn } from 'controller/types';

import type { IFeedItem } from 'api/feed';
import { GroupAppKey } from 'store/groups/types';

export default (function (vm, store, params) {
  return {
    name: 'group.discussion.post',
    url: '/:feedItemId?commentId',
    data: {
      sectionId: 'group',
      application: GroupAppKey.FEED_APP,
    },
    async onExit() {
      await vm._.comments.dispose();
    },
    resolve: [
      {
        token: 'feedItem',
        deps: ['$stateParams', 'groupId', 'feedAvailable'],
        resolveFn(
          stateParams: RawParams,
          groupId: string,
          isFeedAvailable: boolean,
        ) {
          if (isFeedAvailable) {
            return vm.feed$.get(groupId, stateParams.feedItemId);
          }
        },
      },
      {
        token: 'comments',
        deps: ['feedItem'],
        async resolveFn(feedItem?: IFeedItem) {
          return vm._.comments.fetch(feedItem ? [feedItem] : []);
        },
      },
    ],
  };
} as StateDeclarationFn);
