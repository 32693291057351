import * as files from 'api/files';

import { createAsyncThunk } from '../utils';

export const fetch = createAsyncThunk(
  'files:fetch',
  async function (groupId: string, thunkAPI) {
    const response = await thunkAPI.extra.httpClient.request(
      files.fetch(groupId),
    );

    return response.data;
  },
);
