import type { StateDeclarationFn } from 'controller/types';

import { GroupAppKey } from 'store/groups/types';
import { selectIsAppAvailable } from 'store/selectors';

export default (function (vm, store) {
  return {
    name: 'group.files',
    url: '/files',
    data: {
      sectionId: 'group',
      title: 'groups-web.files',
      application: GroupAppKey.FILES_APP,
    },
    params: {
      sorting: {
        value: 'POST_DATE',
      },
    },
    resolve: [
      {
        token: 'filesAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.FILES_APP,
          });
        },
      },
      {
        token: 'files',
        deps: ['groupId', 'filesAvailable'],
        resolveFn(groupId: string, filesAvailable: boolean) {
          if (filesAvailable) {
            return vm.files$.fetch(groupId);
          }
        },
      },
    ],
  };
} as StateDeclarationFn);
