import * as events from 'api/events';

import { createAsyncThunk } from '../utils';

export const fetch = createAsyncThunk(
  'events:fetch',
  async function (groupId: string, thunkAPI) {
    return thunkAPI.extra.httpClient
      .request(events.getConnectedEvents(groupId))
      .then((response) => response.data);
  },
);
