import type { UIRouter } from '@wix/tpa-router';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type { IGroup } from 'api/groups/types';
import { SECRET_SLUG } from 'api/groups/constants';

export function configureRouter(router: UIRouter, params: ControllerParams) {
  const { isEditor, isPreview } = params.flowAPI.environment;

  if (isEditor || isPreview) {
    router.urlService.rules.initial({
      state: 'group.discussion.feed',
      params: { slug: SECRET_SLUG },
    });
  }

  router.transitionService.onBefore(
    { to: 'group.**' },
    async function (transition) {
      const params = transition.params();
      const injector = transition.injector();
      const group: IGroup = await injector.getAsync('group');

      if (group.slug !== params.slug) {
        return transition
          .targetState()
          .withParams({ slug: group.slug })
          .withOptions({
            location: 'replace',
            custom: { retain: true },
          });
      }
    },
  );
}
