import { dsl } from '@wix/yoshi-serverless/wrap';

export const create = dsl({
          functionName: 'create',
          fileName: 'api/topics/topics.api',
        });

export const list = dsl({
          functionName: 'list',
          fileName: 'api/topics/topics.api',
        });