import type { ControllerParams } from '@wix/yoshi-flow-editor';
import { type UIRouter, type Transition } from '@wix/tpa-router';

const CORE_RESOLVABLES = ['$stateParams', '$state$', '$transition$'];

/**
 * @TODO move to @wix/tpa-router
 */
export function addLogger(router: UIRouter, params: ControllerParams) {
  return router.transitionService.onCreate({}, handleCreate);

  function handleCreate(transition: Transition) {
    const panorama = params.flowAPI.panoramaClient;

    if (!panorama) {
      return;
    }

    const to = transition.to();
    const name = to.name?.replace(/\./g, '_');

    if (!name) {
      return;
    }

    const transaction = panorama.transaction(name);

    transaction.start();
    transition.promise.then(() => transaction.finish());

    for (const node of transition.treeChanges().to) {
      const resolvables = node.resolvables.filter(
        (resolvable) =>
          typeof resolvable.token === 'string' &&
          !CORE_RESOLVABLES.includes(resolvable.token),
      );

      for (const resolvable of resolvables) {
        if (resolvable.resolved || !resolvable.resolveFn) {
          continue;
        }

        const original = resolvable.resolveFn;
        const transaction = panorama.transaction(`${name}-${resolvable.token}`);

        resolvable.resolveFn = async function () {
          transaction.start();
          const result = await original.apply(this, arguments);
          transaction.finish();
          return result;
        };
      }
    }
  }
}
