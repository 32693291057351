import { isEmpty } from 'lodash';
import type { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';

export class GroupsQueryFilterBuilder {
  private filter: any = {};

  public withTitle(title?: string) {
    if (title) {
      this.filter.lowerCaseTitle = { $contains: title.toLowerCase() };
    }
    return this;
  }

  public withPrivacyStatus(status?: PrivacyStatus) {
    if (status) {
      this.filter.privacyLevel = status;
    }
    return this;
  }

  public withGroupIds(groupIds?: string[]) {
    if (groupIds) {
      this.filter.groupId = { $in: groupIds };
    }
    return this;
  }

  public build() {
    if (!isEmpty(this.filter)) {
      return this.filter;
    }
    return;
  }
}
