import type { ControllerParams } from '@wix/yoshi-flow-editor';

import {
  STORES_APP_DEFINITION_ID,
  EVENTS_APP_DEFINITION_ID,
  BOOKINGS_APP_DEFINITION_ID,
} from '../../constants';

export async function VerticalsVM(params: ControllerParams) {
  const { wixCodeApi } = params.flowAPI.controllerConfig;
  const hasEventsInstalled = await hasEventsAppInstalled();
  const hasBookingsInstalled = await hasBookingsAppInstalled();
  const hasStoresInstalled = await hasStoresAppInstalled();

  return {
    verticals$: {
      hasEventsInstalled,
      hasBookingsInstalled,
      hasStoresInstalled,
    },
  };

  async function hasStoresAppInstalled() {
    try {
      await wixCodeApi.site
        .getPublicAPI(STORES_APP_DEFINITION_ID)
        .catch(() => null);

      return true;
    } catch {
      return false;
    }
  }

  async function hasEventsAppInstalled() {
    try {
      await wixCodeApi.site
        .getPublicAPI(EVENTS_APP_DEFINITION_ID)
        .catch(() => null);

      return true;
    } catch {
      return false;
    }
  }

  async function hasBookingsAppInstalled() {
    try {
      await wixCodeApi.site
        .getPublicAPI(BOOKINGS_APP_DEFINITION_ID)
        .catch(() => null);

      return true;
    } catch {
      return false;
    }
  }
}

export type IVerticalsVM = Awaited<ReturnType<typeof VerticalsVM>>;
