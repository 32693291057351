import { dsl } from '@wix/yoshi-serverless/wrap';

export const joinGroup = dsl({
          functionName: 'joinGroup',
          fileName: 'api/membership/membership.api',
        });

export const requestJoinGroup = dsl({
          functionName: 'requestJoinGroup',
          fileName: 'api/membership/membership.api',
        });

export const leaveGroup = dsl({
          functionName: 'leaveGroup',
          fileName: 'api/membership/membership.api',
        });

export const cancelRequest = dsl({
          functionName: 'cancelRequest',
          fileName: 'api/membership/membership.api',
        });

export const addAdminRole = dsl({
          functionName: 'addAdminRole',
          fileName: 'api/membership/membership.api',
        });

export const removeAdminRole = dsl({
          functionName: 'removeAdminRole',
          fileName: 'api/membership/membership.api',
        });

export const removeFromGroup = dsl({
          functionName: 'removeFromGroup',
          fileName: 'api/membership/membership.api',
        });

export const addMembersToGroup = dsl({
          functionName: 'addMembersToGroup',
          fileName: 'api/membership/membership.api',
        });

export const listMemberAnswers = dsl({
          functionName: 'listMemberAnswers',
          fileName: 'api/membership/membership.api',
        });