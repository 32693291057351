import { GroupAppKey } from '@wix/ambassador-social-groups-v1-group-app/types';

export const SECRET_SLUG = 'secret-group-slug-used-for-editor';
export const FEED_WIDGET_SECRET_SLUG =
  'secret-publicgroup-slug-used-for-editor';

export const SUPPORTED_APPS = [
  GroupAppKey.MEMBERS_APP,
  GroupAppKey.FEED_APP,
  GroupAppKey.ABOUT_APP,
  GroupAppKey.GALLERY_APP,
  GroupAppKey.FILES_APP,
  GroupAppKey.EVENTS_APP,
  GroupAppKey.CUSTOM_APP,
  GroupAppKey.CUSTOM_APP_2,
  GroupAppKey.CUSTOM_APP_3,
];

export const ALWAYS_ENABLED_APPS = [
  GroupAppKey.FEED_APP,
  GroupAppKey.ABOUT_APP,
];
