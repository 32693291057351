import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { topicsAdapter } from './adapter';
import * as thunks from './thunks';
import { ITopicsStateExtras } from './types';

export const initialState = topicsAdapter.getInitialState<ITopicsStateExtras>({
  statuses: {
    fetch: {},
    create: {},
  },
});

export const topicsSlice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    incrementCounter(state, action: PayloadAction<string[]>) {
      action.payload.forEach((id) => {
        const topic = state.entities[id];

        if (topic) {
          topic.count += 1;
        }
      });
    },

    decrementCounter(state, action: PayloadAction<string[]>) {
      action.payload.forEach((id) => {
        const topic = state.entities[id];

        if (topic) {
          topic.count -= 1;
        }
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(thunks.create.pending, (state) => {
        state.statuses.create.pending = true;
        state.statuses.create.error = false;
      })
      .addCase(thunks.create.rejected, (state) => {
        state.statuses.create.pending = false;
        state.statuses.create.error = true;
      })
      .addCase(thunks.create.fulfilled, (state, action) => {
        state.statuses.create.pending = false;
        state.statuses.create.error = false;
        state.statuses.create.itemId = action.payload.id;

        topicsAdapter.setOne(state, action.payload);
      });

    builder
      .addCase(thunks.fetch.pending, (state) => {
        state.statuses.fetch.pending = true;
        state.statuses.fetch.error = false;
      })
      .addCase(thunks.fetch.rejected, (state, action) => {
        state.statuses.fetch.pending = false;
        state.statuses.fetch.error = true;
      })
      .addCase(thunks.fetch.fulfilled, (state, action) => {
        state.statuses.fetch.pending = false;
        state.statuses.fetch.error = false;

        topicsAdapter.setAll(state, action.payload);
      });
  },
});
