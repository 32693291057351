import type { RawParams } from '@wix/tpa-router';

import type { IFeedListResponse } from 'api/feed';
import { EFilterKeys } from 'api/feed/constants';

import type { StateDeclarationFn } from 'controller/types';
import { GroupAppKey } from 'store/groups/types';

export default (function (vm, store, params) {
  const { isMobile } = params.flowAPI.environment;

  return {
    name: 'group.discussion.feed',
    url: `?${EFilterKeys.TOPICS}&${EFilterKeys.CURSOR}`,
    async onExit() {
      await vm._.comments.dispose();
    },
    data: {
      sectionId: 'group',
      title: 'groups-web.discussion',
      application: GroupAppKey.FEED_APP,
    },
    params: {
      createPost: {
        type: 'bool',
        dynamic: true,
        value: null,
      },
    },
    resolve: [
      {
        token: 'groupFeed',
        deps: ['$stateParams', 'groupId', 'feedAvailable'],
        resolveFn(
          params: RawParams,
          groupId: string,
          isFeedAvailable: boolean,
        ) {
          const {
            [EFilterKeys.CURSOR]: cursor,
            [EFilterKeys.TOPICS]: topicId,
          } = params;

          if (!isFeedAvailable) {
            return;
          }

          if (!topicId) {
            return vm.feed$.fetch(groupId, { cursor });
          }

          return vm.feed$.filter(groupId, {
            filter: { [EFilterKeys.TOPICS]: topicId },
          });
        },
      },
      {
        token: 'comments',
        deps: ['groupFeed'],
        async resolveFn(feed?: IFeedListResponse) {
          if (isMobile) {
            return;
          }

          return vm._.comments.fetch(feed?.items);
        },
      },
    ],
  };
} as StateDeclarationFn);
