import type { ControllerParams } from '@wix/yoshi-flow-editor';

import {
  getPricingPlansPublicAPI,
  NavigateToPackagePickerOptions,
  PricingPlansPublicAPI,
} from '@wix/pricing-plans-tpa-api';

import type { IRootStore } from 'store/index';

export function PricingPlansVM(params: ControllerParams, store: IRootStore) {
  let api: PricingPlansPublicAPI;

  return {
    _: {
      pricingPlans: {
        init,
      },
    },
    pricingPlans$: {
      navigateToPackagePicker,
    },
  };

  async function init() {
    try {
      api = await getPricingPlansPublicAPI(params.controllerConfig.wixCodeApi);
    } catch {
      // most likely PP not installed
    }

    return api;
  }

  async function navigateToPackagePicker(
    options: NavigateToPackagePickerOptions,
  ) {
    api?.navigateToPricingPage(options);
  }
}

export type IPricingPlansVM = ReturnType<typeof PricingPlansVM>;
