import type { StateDeclarationFn } from 'controller/types';

import { GroupAppKey } from 'store/groups/types';
import { selectIsAppAvailable } from 'store/selectors';

export default (function (vm, store, params) {
  const { isMobile } = params.flowAPI.environment;
  return {
    url: '/media',
    name: 'group.media',
    data: {
      sectionId: 'group',
      title: 'groups-web.media',
      application: GroupAppKey.GALLERY_APP,
    },
    resolve: [
      {
        token: 'mediaAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.GALLERY_APP,
          });
        },
      },
      {
        token: 'media',
        deps: ['groupId', 'mediaAvailable'],
        resolveFn(groupId: string, isMediaAvailable: boolean) {
          if (isMediaAvailable) {
            return vm.media$.fetch(groupId, isMobile);
          }
        },
      },
    ],
  };
} as StateDeclarationFn);
