import { dsl } from '@wix/yoshi-serverless/wrap';

export const list = dsl({
          functionName: 'list',
          fileName: 'api/group-requests/group-requests.api',
        });

export const create = dsl({
          functionName: 'create',
          fileName: 'api/group-requests/group-requests.api',
        });

export const remove = dsl({
          functionName: 'remove',
          fileName: 'api/group-requests/group-requests.api',
        });

export const approve = dsl({
          functionName: 'approve',
          fileName: 'api/group-requests/group-requests.api',
        });

export const decline = dsl({
          functionName: 'decline',
          fileName: 'api/group-requests/group-requests.api',
        });