import { dsl } from '@wix/yoshi-serverless/wrap';

export const fetch = dsl({
          functionName: 'fetch',
          fileName: 'api/central-feed/central-feed.api',
        });

export const query = dsl({
          functionName: 'query',
          fileName: 'api/central-feed/central-feed.api',
        });