import { dsl } from '@wix/yoshi-serverless/wrap';

export const getMember = dsl({
          functionName: 'getMember',
          fileName: 'api/members/members.api',
        });

export const getGroupMember = dsl({
          functionName: 'getGroupMember',
          fileName: 'api/members/members.api',
        });

export const queryGroupMembers = dsl({
          functionName: 'queryGroupMembers',
          fileName: 'api/members/members.api',
        });

export const queryNonGroupMembers = dsl({
          functionName: 'queryNonGroupMembers',
          fileName: 'api/members/members.api',
        });

export const listGroupMembers = dsl({
          functionName: 'listGroupMembers',
          fileName: 'api/members/members.api',
        });