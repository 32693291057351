import type { StateDeclarationFn } from 'controller/types';
import { GroupAppKey } from 'store/groups/types';
import { selectGroupAuthorId } from 'store/selectors';

export default (function (vm, store) {
  return {
    name: 'group.about',
    url: '/about?openEditor&origin',
    params: {
      openEditor: { squash: true, type: 'bool', value: false, dynamic: true },
      origin: { squash: true, dynamic: true },
    },
    data: {
      title: 'groups-web.about',
      application: GroupAppKey.ABOUT_APP,
      sectionId: 'group',
    },
    resolve: [
      {
        token: 'about',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return Promise.all([
            vm.group$.fetchActivity(groupId),
            vm.group$.fetchRules(groupId),
          ]);
        },
      },
      {
        token: 'author',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          const authorId = selectGroupAuthorId(store.getState(), groupId);

          if (authorId) {
            return vm.members$.fetchProfile(authorId);
          }
        },
      },
    ],
  };
} as StateDeclarationFn);
