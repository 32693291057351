import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import uuid from 'uuid/v4';
import { AuthorizedGroupCreators } from '@wix/ambassador-social-groups-v2-groups-app-settings/types';

import { toastsAdapter } from './toasts';
import * as thunks from './thunks';
import type {
  IApplicationDialog,
  IApplicationState,
  IApplicationUser,
  IShowDialogPayload,
  IShowToastPayload,
} from './types';

export const initialState: IApplicationState = {
  user: {
    following: [] as string[],
  } as IApplicationUser,
  appData: {
    metaSiteId: '',
    siteDisplayName: '',
  },
  shareProfileConsent: {},
  toasts: toastsAdapter.getInitialState(),
  dialogs: {
    createPost: {},
    joinGroup: {},
    disclaimer: {},
    groupQuestions: {},
    eventsRestriction: {},
    paidPlansRestriction: {},
    futurePlanDialog: {},
    cancelJoinRequest: {},
    leaveGroup: {},
    joinedGroups: {},
    suggestedGroups: {},
    createGroup: {},
    addMembers: {},
    inviteMembers: {},
    groupSettings: {},
  },
  settings: {
    authorizedGroupCreators: AuthorizedGroupCreators.UNKNOWN_POLICY,
  },
  status: {
    follow: {},
  },
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setAppData(state, action: PayloadAction<Record<string, any>>) {
      state.appData = {
        ...state.appData,
        ...action.payload,
      };
    },

    shareProfileConsent(
      state,
      action: PayloadAction<{ groupId: string; value: boolean }>,
    ) {
      const { groupId, value } = action.payload;

      state.shareProfileConsent[groupId] = value;
    },

    showToast(state, action: PayloadAction<IShowToastPayload>) {
      toastsAdapter.setOne(state.toasts, {
        ...action.payload,
        id: uuid(),
      });
    },

    closeToast(state, action: PayloadAction<string>) {
      toastsAdapter.removeOne(state.toasts, action.payload);
    },

    showDialog(state, action: PayloadAction<IShowDialogPayload>) {
      const { dialog, params } = action.payload;

      Object.keys(state.dialogs).forEach((key) => {
        const name = key as IApplicationDialog;
        const dialog = state.dialogs[name];

        state.dialogs[name] = {
          ...dialog,
          isOpen: false,
        };
      });

      state.dialogs[dialog] = {
        isOpen: true,
        params,
      };
    },

    closeDialog: (state, action: PayloadAction<IShowDialogPayload>) => {
      const { dialog, params } = action.payload;

      state.dialogs[dialog] = {
        ...(params || state.dialogs[dialog]),
        isOpen: false,
      };
    },

    closeAllDialogs(state) {
      Object.keys(state.dialogs).forEach((key) => {
        const name = key as IApplicationDialog;
        const dialog = state.dialogs[name];

        state.dialogs[name] = {
          ...dialog,
          isOpen: false,
        };
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(thunks.fetchSettings.fulfilled, (state, action) => {
      state.settings = action.payload || state.settings;
    });

    builder
      .addCase(thunks.follow.pending, (state, action) => {
        const memberId = action.meta.arg;

        state.status.follow[memberId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.follow.rejected, (state, action) => {
        const memberId = action.meta.arg;

        state.status.follow[memberId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.follow.fulfilled, (state, action) => {
        const memberId = action.meta.arg;

        state.status.follow[memberId] = {
          loading: false,
          error: false,
        };

        state.user.following.push(memberId);
      });

    builder
      .addCase(thunks.unfollow.pending, (state, action) => {
        const memberId = action.meta.arg;

        state.status.follow[memberId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.unfollow.rejected, (state, action) => {
        const memberId = action.meta.arg;

        state.status.follow[memberId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.unfollow.fulfilled, (state, action) => {
        const memberId = action.meta.arg;

        state.status.follow[memberId] = {
          loading: false,
          error: false,
        };

        state.user.following = state.user.following.filter(
          (id) => id !== memberId,
        );
      });

    builder
      .addCase(thunks.fetchCurrentUserProfile.pending, (state, action) => {
        state.user = {
          name: '',
          following: [],
          role: action.meta.role,
          loggedIn: action.meta.loggedIn,
          isSiteAdmin: action.meta.role === 'Admin',
        };
      })
      .addCase(thunks.fetchCurrentUserProfile.fulfilled, (state, action) => {
        state.user = action.payload as IApplicationUser;
      });
  },
});
