import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type { IRootStore } from 'store/index';
import type {
  IApplicationDialog,
  IShowDialogPayload,
  IShowToastPayload,
} from 'store/application/types';
import * as application from 'store/application';

import { MembersAreaPublicApi } from '../../types';
import { MEMBERS_APP_DEFINITION_ID } from '../../constants';

export async function ApplicationVM(
  params: ControllerParams,
  store: IRootStore,
) {
  const { platformAPIs, wixCodeApi } = params.flowAPI.controllerConfig;
  const hasSocialPages = await hasSocialPagesInstalled();
  const siteDisplayName = getSiteDisplayName();

  store.dispatch(
    application.actions.setAppData({
      metaSiteId: platformAPIs.bi?.metaSiteId,
      siteDisplayName,
    }),
  );

  return {
    application$: {
      hasSocialPages,
      fetchUserProfile,
      shareProfileConsent,
      showToast,
      closeToast,
      showDialog,
      closeDialog,
      follow,
      unfollow,
      promptLogin,
      fetchSettings,
    },
  };

  function getSiteDisplayName() {
    const { wixAPI } = params.flowAPI.viewerScriptFlowAPI;
    return wixAPI.site.getSiteDisplayName?.() || '';
  }

  async function hasSocialPagesInstalled() {
    try {
      const maPublicApi: MembersAreaPublicApi = await wixCodeApi.site
        .getPublicAPI(MEMBERS_APP_DEFINITION_ID)
        .catch(() => null);

      return await maPublicApi?.hasSocialPages();
    } catch {
      return false;
    }
  }

  function fetchSettings() {
    store.dispatch(application.thunks.fetchSettings());
  }

  function promptLogin() {
    store.dispatch(application.thunks.login());
  }

  function shareProfileConsent(groupId: string, value: boolean) {
    store.dispatch(application.actions.shareProfileConsent({ groupId, value }));
  }

  function follow(memberId: string) {
    store.dispatch(application.thunks.follow(memberId));
  }

  function unfollow(memberId: string) {
    store.dispatch(application.thunks.unfollow(memberId));
  }

  function showToast(params: IShowToastPayload) {
    store.dispatch(application.actions.showToast(params));
  }

  function closeToast(toastId: string) {
    store.dispatch(application.actions.closeToast(toastId));
  }

  function showDialog(params: IShowDialogPayload) {
    store.dispatch(application.actions.showDialog(params));
  }

  function closeDialog(
    dialog: IApplicationDialog,
    params?: Record<string, any>,
  ) {
    store.dispatch(application.actions.closeDialog({ dialog, params }));
  }

  function fetchUserProfile() {
    return store.dispatch(application.thunks.fetchCurrentUserProfile());
  }
}

export type IApplicationVM = Awaited<ReturnType<typeof ApplicationVM>>;
