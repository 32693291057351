import { type UIRouter, waitForTransition } from '@wix/tpa-router';
import { ControllerParams } from '@wix/yoshi-flow-editor';

import type { Resolvables } from 'controller/types';

export function resolve(router: UIRouter, params: ControllerParams) {
  const { isSSR } = params.flowAPI.environment;

  return function (resolvables?: Resolvables) {
    return waitForTransition(router, {
      resolvables,
      to: 'group.**',
      disableWaitForResolvables: !isSSR,
      blacklist: ['SEO', 'ppApi'],
    });
  };
}
