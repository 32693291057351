import _ from 'lodash';
import { UIRouter } from '@wix/tpa-router';
import type { ControllerParams } from '@wix/yoshi-flow-editor';
import {
  editorPlugin,
  verticalsPlugin,
  viewerPlugin,
} from '@wix/tpa-router/plugins';

import groupRoutes from 'Group/routes';
import groupsRoutes from 'Groups/routes';

import type { IRootStore } from 'store/types';

import tpaRoutes from './tpa.route';
import type { IAppData } from '../types';
import type { IViewModel, StateDeclarationFn } from './types';

/**
 * Self explanatory
 * initializes router with necessary plugins
 */
export function initializeRouter(params: ControllerParams) {
  const { isViewer } = params.flowAPI.environment;
  const { verticalsBaseUrls } = params.appData as IAppData;

  const router = new UIRouter();

  if (isViewer) {
    router.plugin(viewerPlugin(params.flowAPI));
  } else {
    router.plugin(editorPlugin(params.flowAPI));
  }

  router.plugin(verticalsPlugin(), { baseUrls: verticalsBaseUrls });

  return router;
}

/**
 * Registers all internal application routes
 */
export function registerApplicationRoutes(
  router: UIRouter,
  vm: IViewModel,
  store: IRootStore,
  params: ControllerParams,
) {
  const declarationFns = _.flatten([tpaRoutes, groupRoutes, groupsRoutes]);
  return registerApplicationRoute(declarationFns, router, vm, store, params);
}

export function registerApplicationRoute(
  declarationFns: StateDeclarationFn[],
  router: UIRouter,
  vm: IViewModel,
  store: IRootStore,
  params: ControllerParams,
) {
  for (const declarationFn of declarationFns) {
    const states = _.flatten([declarationFn(vm, store, params)]);

    for (const state of states) {
      router.stateRegistry.register(state);
    }
  }
}

/**
 * reloads the root state on user login
 */
export function reloadOnLogin(router: UIRouter, params: ControllerParams) {
  const { wixCodeApi } = params.controllerConfig;

  wixCodeApi.user.onLogin(() => router.stateService.reload());
}
