import type { StateDeclarationFn } from 'controller/types';

import { GroupAppKey } from 'store/groups/types';
import { selectIsAppAvailable, selectGroup } from 'store/selectors';
import { Role as GroupRole } from '@wix/ambassador-social-groups-v2-group-role/types';

export default (function (vm, store, params) {
  const { experiments } = params.flowAPI;
  return {
    abstract: true,
    url: '/discussion',
    name: 'group.discussion',
    data: { sectionId: 'group' },
    resolve: [
      {
        token: 'feedAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.FEED_APP,
          });
        },
      },
      {
        token: 'membersAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.MEMBERS_APP,
          });
        },
      },
      {
        token: 'eventsAvailable',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.EVENTS_APP,
          });
        },
      },
      {
        token: 'topics',
        deps: ['groupId', 'feedAvailable'],
        resolveFn(groupId: string, isFeedAvailable: boolean) {
          if (isFeedAvailable) {
            return vm.topics$.fetch(groupId);
          }
        },
      },
      {
        token: 'events',
        deps: ['groupId', 'eventsAvailable'],
        resolveFn(groupId: string, isEventsAvailable: boolean) {
          if (isEventsAvailable) {
            return vm.events$.fetch(groupId);
          }
        },
      },
      {
        token: 'members',
        deps: ['groupId', 'membersAvailable'],
        resolveFn(groupId: string, isMembersAvailable: boolean) {
          if (isMembersAvailable) {
            return vm.members$.fetch(groupId, 5);
          }
        },
      },
      {
        token: 'onboarding',
        deps: ['groupId', 'isJoined'],
        resolveFn(groupId: string, isJoined: boolean) {
          const group = selectGroup(store.getState(), groupId);
          const admins = experiments.enabled('specs.groups.AdminOnboarding');
          const members = experiments.enabled('specs.groups.MemberOnboarding');

          const enabled = {
            [GroupRole.ADMIN]: admins,
            [GroupRole.MEMBER]: members,
            [GroupRole.UNKNOWN_ROLE]: false,
          };

          if (isJoined && enabled[group.role]) {
            vm.onboarding$.initialize(groupId);
          }
        },
      },
    ],
  };
} as StateDeclarationFn);
